@mixin outlined-button($invert: false, $width: 70px, $font-size: 12px) {
  background: white;
  color: white;
  border: 1px solid var(--mainColor);
  color: var(--mainColor);
  width: $width;
  font-size: $font-size !important;
  font-family: var(--fontFamily) !important;
  transition: all 0.3s;

  @if $invert {
    background: var(--mainColor);
    color: white;
  }
}

@mixin common-button() {
  font-size: 13px !important;
  padding: 10px 15px !important;
  min-width: 70px !important;
  background: transparent;
  transition: all 0.3s;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &--wide {
    width: 100%;
  }

  &--icon {
    i {
      color: white !important;
      margin-right: 5px;
    }
  }
}

// Outlined button with both colored border and text
.outline-button {
  @include common-button();
  border: 1px solid var(--mainColor) !important;
  color: var(--mainColor) !important;
}

// Small button to include in rows
.table-outline-button {
  @extend .outline-button;
  font-size: 11px !important;
  border-radius: 5px;
  padding: 3px 5px !important;
  min-width: 55px !important;

  &--inverted {
    background-color: var(--mainColor);
    color: white !important;
  }
}

// Button with coloring but no border and background
.no-outline-button {
  @include common-button();
  color: var(--mainColor) !important;

  .mat-icon {
    color: #00000061;
  }
}

// Button with white text and border with coloring
.common-button {
  @include common-button();
  background: var(--mainColor) !important;
  color: white !important;
}

// Just add the dimensions
.plain-button {
  @include common-button();

  &--main {
    color: var(--mainColor) !important;
  }

  &--with-button {
    .mdc-button__label {
      display: flex;
      align-items: center;
    }

    .material-symbols-outlined {
      margin-right: 5px;
    }
  }
}

.compact-more-button {
  padding: 0px 10px !important;

  .mat-icon {
    margin-right: 0;
  }
}

.attribute-btn {
  color: #707070;
  background-color: transparent;
  border: none;
  font-weight: 600 !important;

  span {
    font-weight: 300 !important;
  }
}
