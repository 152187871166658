.secondary-actions {
  &__action {
    .mdc-list-item {
      &__primary-text {
        display: flex;
        align-items: center;

        lib-management-actions,
        .material-symbols-rounded {
          width: 25px;
          height: 25px;
          display: flex;
          align-items: center;
          margin-right: 5px;
        }
      }
    }
  }

  &--no-icon {
    .material-symbols-rounded,
    .mat-icon {
      display: none;
    }
  }
}

.action-text {
  span {
    color: #000000de !important;
  }
}
