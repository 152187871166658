@import 'globals/buttons';
@import 'globals/actions';

$table-border: #e0e0e0;

// Simple Table. Used in Contacts
.ngx-datatable.material.flg-simple-table {
  font-family: 'Open Sans' !important;
  border: 1px solid $table-border;

  .datatable-header {
    border-bottom: 0 !important;
  }

  .flg-simple-table__header {
    text-transform: none !important;
    font-size: 14px !important;
    color: black !important;
    font-weight: 500 !important;
  }

  .flg-simple-table__cell {
    .datatable-body-cell-label {
      font-family: 'Open Sans' !important;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &--email {
      font-size: 12px !important;
    }
  }
}

// General Table. Used on Grids
.ngx-datatable.flg-grid-table {
  font-family: 'Open Sans' !important;
  border-radius: 8px;
  border: 1px solid $table-border;
  background: var(--lpx-card-bg);

  &--borderless {
    border: none;

    .datatable-header,
    .datatable-header-cell-template-wrap {
      background: #f5f5f5 !important;
    }

    .datatable-header-cell-template-wrap {
      color: var(--primaryColor);
    }

    .datatable-header {
      border: none !important;
    }
  }

  &--override-scroll {
    &.scroll-horz .datatable-body {
      overflow: hidden !important;
    }
  }

  &--clickable {
    .datatable-body-row {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .datatable-header {
    display: flex;
    border-bottom: 1px solid $table-border;
    padding: 10px 13px;
    height: auto;

    .datatable-row-center,
    .datatable-row-right {
      display: flex;
      align-items: center;
    }
  }

  .datatable-header-cell {
    align-items: center !important;
    text-transform: capitalize !important;
    font-weight: 600 !important;
    background-color: var(--lpx-card-bg) !important;
    user-select: none;

    .draggable {
      cursor: grab !important;
    }

    .resize-handle {
      width: 10px;
    }
  }

  .datatable-body {
    overscroll-behavior-x: none;
  }

  .datatable-body-row {
    border-bottom: 1px solid $table-border;
    padding: 0 1em;

    .datatable-body-cell {
      border-bottom: 1px solid $table-border;

      &:last-child {
        margin-right: initial;
      }

      .datatable-body-cell-label {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin: 10px;
        padding-right: 10px;

        // Removes extra margin and padding in the action column.
        // Used by NgxDatatableActionWidthFix
        &.action-width-fix {
          margin-right: 0;
          padding-right: 0;
        }
      }
    }
  }

  .disabled {
    pointer-events: none;

    .mat-icon,
    .material-symbols-rounded {
      color: #ddd;
    }
  }

  .datatable-footer-inner {
    padding: 1em !important;
  }

  .empty-row {
    padding: 1em;
  }

  // Use this class to add a proper background to a fixed column cell
  .fixed-column {
    .datatable-body-cell-label {
      background: var(--lpx-card-bg) !important;
    }
  }

  .actions-column {
    text-align: center;
  }
}

.flg-grid-table-actions {
  display: flex;
  background-color: var(--lpx-card-bg) !important;
  height: 36px;
  line-height: 1;

  &--plain {
    background-color: unset !important;
  }

  .single-action {
    display: inline-flex;
    align-items: center;
    height: 30px;
    width: 30px;
    margin-right: 10px;
    transition: all 0.3s;
    padding: 0 !important;
    border: none;

    &:disabled {
      cursor: not-allowed;
    }

    &--centered {
      justify-content: center;
    }

    &--wide {
      width: 60px !important;
      align-self: normal;
    }

    .material-symbols-rounded {
      &:hover {
        color: var(--mainColor);
      }
    }
  }

  .primary-action {
    justify-content: flex-start;
    width: 120px;

    button {
      min-width: 65px;
      max-width: 150px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      line-height: 21px;
    }
  }

  .primary-action,
  .secondary-actions {
    display: inline-flex;
    flex-shrink: 0;
  }

  .secondary-actions {
    align-items: baseline;

    &--alone {
      justify-content: flex-start;
    }
  }
}
