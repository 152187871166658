.open-sans {
  font-family: 'Open Sans', sans-serif !important;
}

.bold {
  font-weight: bold !important;
}

.mat-mdc-card {
  box-shadow: none !important;
  border-width: 1px !important;
  border-color: #e0e0e0 !important;
  border-style: solid !important;
  border-radius: 10px !important;
  overflow: hidden !important;
}

.material-symbols-outlined {
  font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24;
}

.fs-16 {
  font-size: 16px !important;
  color: #212121;
}

.fs-14 {
  font-size: 14px !important;
  color: #212121;
}

.fs-12 {
  font-size: 12px !important;
  color: #666666;
}

.btn-sm {
  height: 36px;
}

.gray-header-table {
  .ngx-datatable {
    .datatable-header {
      .datatable-header-cell {
        background-color: #f5f5f5 !important;
      }
    }
  }
}

abp-manage-profile {
  display: block;
  width: 70%;
  margin: auto;
}

.warning-card {
  .card-body {
    background-color: #fff4e5 !important;
  }

  a {
    color: #917040;
    text-decoration: underline;
  }

  mat-icon {
    color: #f5ad70;
    flex-shrink: 0;
  }

  h3 {
    color: #917040;
    line-height: 1.1;
    font-weight: 400 !important;
  }

  button span {
    color: #917040;
    text-align: left;
  }

  button {
    background-color: #fff4e5 !important;
    letter-spacing: normal;
    font-size: 14px !important;
    font-weight: bold !important;
  }
}

abp-personal-settings-phone-number {
  display: none;
}

.questionnaire-radio-group {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  align-items: flex-start;

  label {
    font-size: 14px !important;
  }
}

.questionnaire-checkbox-group {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  align-items: flex-start;

  label {
    font-size: 14px !important;
  }
}

.green-spinner {
  .mat-mdc-progress-spinner {
    --mdc-circular-progress-active-indicator-color: green !important;
  }
}

.yellow-spinner {
  .mat-mdc-progress-spinner {
    --mdc-circular-progress-active-indicator-color: rgb(255, 196, 0) !important;
  }
}

.blue-spinner {
  .mat-mdc-progress-spinner {
    --mdc-circular-progress-active-indicator-color: #2196f3 !important;
  }
}

.red-spinner {
  .mat-mdc-progress-spinner {
    --mdc-circular-progress-active-indicator-color: red !important;
  }
}
